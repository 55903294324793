@import '~texkit/dist/styles/variables';

.tx-number-modal {
  .modal-graphic {
    max-width: 288px;
  }
  .modal-subtitle {
    font-size: 20px;

    @media #{$mq-max-xs} {
      font-size: 16px;
    }
  }
}