@import '~texkit/dist/styles/variables';
@import '~texkit/dist/styles/placeholders';

.hero {
  color: $color-white;
  background: $color-blue url('../../../assets/hero/hero-graphic-mobile.svg')
    no-repeat;
  background-size: cover;

  @media #{$mq-min-md} {
    background-image: url('../../../assets/hero/hero-graphic-desktop.svg');
    background-position: center top;
  }

  &-body {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ($padding * 4.5) 0;
    text-align: center;
    min-height: 240px;

    @media #{$mq-min-md} {
      min-height: 320px;
      padding: ($padding * 3) 0;
    }
  }

  &-title {
    font-size: $font-size-h1;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: normal;

    img {
      width: 138px;
    }

    @media #{$mq-min-md} {
      font-size: 64px;
      line-height: 80px;
    }
  }

  &-actions {
    position: absolute;
    width: auto;
    right: 0;
    bottom: -24px;

    @media #{$mq-min-md} {
      bottom: -28px;
    }

    .button-circle {
      width: 64px;
      height: 64px;
      box-shadow: 0 16px 24px -8px rgba(0, 0, 0, 0.15);

      @media #{$mq-min-md} {
        width: 72px;
        height: 72px;
      }

      &:hover {
        box-shadow: 0 16px 24px -8px rgba(0, 0, 0, 0.25);
      }

      .icon {
        font-size: 24px;

        @media #{$mq-min-md} {
          font-size: 32px;
        }
      }
    }

    .dropdown-menu {
      margin-top: ($margin / 2);
    }
  }
}
