@import '~texkit/dist/styles/variables';

.auto-unlink-subtitle-last {
    margin-top: 0px;
}

.tpwd-autounlink-modal {
    padding: 0px;
}

.auto-unlink-subtitle {
    margin: 0px;
}

.auto-unlink-txNumber {
    text-align: -webkit-center;
    margin: 0px;
    
    .text-container {
        text-align: -webkit-center;
    }
}


.ButtonDanger {
    color: $color-white;
    background-color: $color-red-dark;
    border: 2px solid $color-red-dark;

    &:hover {
        color: $color-white;
        background-color: darken($color-red-dark, 15%);
        border-color: darken($color-red-dark, 15%);
    }
}