.cant-select-modal {
    .modal-title {
        max-width: 664px;
    }
    
    .modal-subtitle {
        max-width: 664px;
    }
    
    .modal-body {
        @media (min-width: 600px) {
            padding: 14px;
        }
    }
    
    .modal-content { 
        max-width: 664px;
        align-items: center;
        justify-content: center;
    }
    
    .modal-close-button { 
        width: auto;
        margin-top: 20px;
    }
}
