@import '~texkit/dist/styles/variables';
@import '~texkit/dist/styles/placeholders';

.input {
  border: 2px solid $color-gray-light;

  &-label {
    display: block;
    line-height: 100%;
    font-size: $font-size-base;
  }

  &-optional {
    color: $color-blue;
    padding-left: 8px;
    text-transform: none;
  }

  &-message {
    margin-top: -6px;
    padding: ($padding * 0.75) $padding ($padding / 2) $padding;
    font-size: $font-size-sm;
    line-height: 1.4;
    color: $color-white;
    background: $color-red-dark;
    border-radius: 0 0 $input-border-radius $input-border-radius;

    .input-wrapper.hasHint & {
      background-color: $color-blue-dark;
    }

    .input-wrapper.hasError & {
      background-color: $color-red-dark;
    }
  }

  &-help {
    display: block;
    margin-top: $margin / 2;
    color: inherit;
    font-size: $font-size-sm;
    padding-left:40px;
  }

  &-icon {
    position: absolute;
    top: ($padding * 1.75) - 2px;
    right: $padding;
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: $color-blue;
    transform: translate(0, 0);
    transition: transform 0.2s ease-in-out;

    &-type-toggle {
      @extend %button-reset;
      position: absolute;
      line-height: 0;
      color: $color-black;
      padding: 0;

      .input-type-password .input ~ & {
        display: block;
      }

      .icon {
        position: relative;
      }
    }

    &-select-arrow {
      color: $color-black;
      pointer-events: none;
    }

    .input[value='']:not(:focus) ~ & {
      transform: translate(0, -6px);
    }
  }

  &-wrapper {
    position: relative;
    width: 100%;
    margin-top: $margin / 2;
    margin-bottom: $margin / 2;
    text-align: left;

    &.hasHelpText {
      margin-bottom: $margin * 1.5;

      @media #{$mq-min-sm} {
        margin-bottom: $margin * 2;
      }
    }

    &-field {
      position: relative;
    }
  }

  &-wrapper.input-borderless & {
    border-color: transparent;
  }

  &-wrapper:not(.input-checkbox):not(.input-radio) {
    .input {
      position: relative;
      display: block;
      border-radius: $input-border-radius;
      width: 100%;
      height: 64px;
      padding: 0 ($padding * 1.5) 0 ($padding * 1.25);
      font-size: $font-size-base;
      font-family: inherit;
      color: $color-black;
      font-weight: 500;
      background-color: $color-white;
      appearance: none;

      &:disabled {
        opacity: 0.5;
        background-color: rgba($color-gray-lighter,0.6);
        pointer-events: none;
        font-weight: bold;
        color: black;

        & + .input-label,
        & ~ .input-icon {
          opacity: 0.5;
          pointer-events: none;
        }
      }

      &:focus {
        border-color: $color-blue;
      }

      &-label {
        position: absolute;
        left: $padding * 1.25;
        color: $color-black;
        transition: $transition-fast;
        pointer-events: none;        
      }

      &[value] + .input-label,
      &[value='']:focus + .input-label {
        margin-top: -12px;
        font-size: $font-size-xs;
      }

      &[value]:focus + .input-label {
        color: $color-blue;
      }

      & + .input-label,
      &[value=''] + .input-label {
        top: 0;
        margin-top: 0;
        font-size: $font-size-base;
        transform: translate(2px, 24px);
      }
    }

    &.input-label-position-above {
      margin-top: $margin * 1.5;
      margin-bottom: $margin * 1.5;

      @media #{$mq-min-sm} {
        margin-top: $margin * 2;
        margin-bottom: $margin * 2;
      }

      .input {
        &-label {
          display: block;
          position: relative;
          left: 0;
          margin-bottom: $margin / 2;
          color: inherit;
          font-size: $font-size-base;
          line-height: $line-height-default;

          .optional {
            color: $color-blue;
            text-transform: capitalize;
          }
        }

        &-icon {
          top: 22px;
          transform: translate(0, 0);
        }
      }
    }

    &.account-group-label.input-label-position-above {
      margin: 16px 0 0 0;
      .hasLabel {
        margin: 24px 0 0 0;
      }
    }

    &.reverify-ssn svg {
      color: $color-blue;
    }

    &.hasLabel:not(.input-label-position-above) .input {
      padding: ($padding * 2) ($padding * 3) $padding ($padding * 1.25);
    }

    &.hasHint .input {
      border-color: $color-blue-dark;
    }

    &.hasError .input {
      border-color: $color-red-dark;

      & + .input-label {
        color: $color-red-dark;
      }
    }

    &.hasError .input-label {
      color: $color-red-dark;
    }

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &-zip-code:not(.input-label-position-inside) &-wrapper-field {
    max-width: 160px;
  }

  &-state:not(.input-label-position-inside) &-wrapper-field {
    max-width: 272px;
  }

  &-confirm-email-check {
    color: #1cd284;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

/* Patch for updated Texkit fix */
.input-text.input-wrapper:not(.input-checkbox):not(.input-radio) .input,
.input-phone.input-wrapper:not(.input-checkbox):not(.input-radio) .input,
.input-date.input-wrapper:not(.input-checkbox):not(.input-radio) .input {
  padding: 16px;
}