@charset "UTF-8";
.pagination-controls-container {
  display: block; }
  .pagination-controls-container .controls {
    text-align: center; }
    .pagination-controls-container .controls .control-option {
      padding: 0 14px;
      display: inline-block;
      position: relative;
      font-size: 0 !important;
      line-height: 35px; }
      .pagination-controls-container .controls .control-option a {
        font-size: 0 !important; }
      .pagination-controls-container .controls .control-option.first, .pagination-controls-container .controls .control-option.last {
        text-decoration: none;
        color: #033b80;
        font-size: 16px;
        cursor: pointer; }
        .pagination-controls-container .controls .control-option.first.disabled, .pagination-controls-container .controls .control-option.last.disabled {
          color: #47525D;
          cursor: initial; }
      .pagination-controls-container .controls .control-option.last::before {
        content: '>∣';
        position: absolute;
        right: 0px;
        top: 0px;
        font-size: 20px; }
      .pagination-controls-container .controls .control-option.first::before {
        content: '∣<';
        position: absolute;
        left: 0px;
        top: 0px;
        font-size: 20px; }
      .pagination-controls-container .controls .control-option.previous {
        margin-right: 12px; }
        .pagination-controls-container .controls .control-option.previous a::before {
          content: '<';
          position: absolute;
          left: 10px;
          top: 0px;
          font-size: 20px; }
      .pagination-controls-container .controls .control-option.next {
        margin-left: 12px; }
        .pagination-controls-container .controls .control-option.next a::before {
          content: '>';
          position: absolute;
          right: 10px;
          top: 0px;
          font-size: 20px; }
    .pagination-controls-container .controls .pagination {
      list-style: none;
      padding: 0;
      display: inline-flex;
      margin: 0; }
      .pagination-controls-container .controls .pagination li {
        margin: 0;
        padding: 0;
        min-width: 25px;
        min-height: 35px;
        text-align: center; }
        .pagination-controls-container .controls .pagination li.active {
          min-width: 35px;
          border-radius: 30px;
          background-color: #f8bb40;
          cursor: initial; }
        .pagination-controls-container .controls .pagination li a {
          text-decoration: none;
          color: #033b80;
          font-size: 16px;
          cursor: pointer;
          vertical-align: middle;
          line-height: 35px;
          display: block; }
        .pagination-controls-container .controls .pagination li.disabled a {
          color: #47525D;
          cursor: initial; }
  @media (min-width: 600px) {
    .pagination-controls-container .controls .control-option {
      font-size: 16px !important; }
      .pagination-controls-container .controls .control-option a {
        font-size: 16px !important; }
      .pagination-controls-container .controls .control-option.first::before {
        left: -8px; }
      .pagination-controls-container .controls .control-option.last::before {
        right: -8px; }
      .pagination-controls-container .controls .control-option.next a {
        padding-right: 18px; }
        .pagination-controls-container .controls .control-option.next a::before {
          right: 0; }
      .pagination-controls-container .controls .control-option.previous a {
        padding-left: 18px; }
        .pagination-controls-container .controls .control-option.previous a::before {
          left: 0; } }
