.agency-card {
  .card-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #6c7782;
    width: 100%;
  }

  .tx-agency-logo__circular {
    img {
        max-width: 122px;
        border: 1px solid lightgrey;
        border-radius: 50%;
    }
  }

  .tx-agency-logo__square {
    img {
        max-width: 122px;
    }
    pointer-events: none;
  }

  &-text {
    width: 100%;
  }

  &-title {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 700;
    color: #6c7782;
  }

  p {
    margin: -(16px * 0.25) 0 0 0;
    color: #6c7782;
  }

  &:hover{
    cursor: pointer;
  }
}