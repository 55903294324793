@import '~texkit/dist/styles/variables';

.server-error-modal {
  .modal-graphic {
    max-width: 300px;
  }  

  .modal-body {
     padding: 50px;
  }

  @media (max-width: 600px) {
    padding: 25px;
  }

  .service-down-description {
    color: $color-black;
    font-size: $font-size-lg;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
}
