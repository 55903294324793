@import '~texkit/dist/styles/variables';

.quantity-picker-wrapper {
    width: 100%;
    display: flex;

    @media (max-width: 600) {
        align-items: center;
        justify-content: center;
    }
}

.quantity-picker {
    position: relative;
    width: 327px;

    &-label {
        -webkit-user-select: none; /* Safari */        
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
    }

    &-input {

        width: 327px;

        input {
            border: 1px solid $color-gray-light;
            text-align: center;
        }

    }

    .plus-icon {
        svg {
            fill: $color-blue;
            width: 22px;
            height: 22px;
        }
        cursor: pointer;

        &-disabled {
            svg {
                fill: $color-gray-light;
                width: 22px;
                height: 22px;
            }
        }
    }

    .minus-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 5px;
        position: absolute;
        z-index: 9999;
        top: 33px;
        width: 56px;
        height: 56px;
        cursor: pointer;
    }

    .plus-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 5px;
        position: absolute;
        z-index: 1;
        top: 33px;
        left: 271px;
        width: 56px;
        height: 56px;
        cursor: pointer;
    }


    .minus-icon {
        svg {
            fill: $color-blue;
            width: 22px;
            height: 22px;
        }
        cursor: pointer;

        &-disabled {
            svg {
                fill: $color-gray-light;
                width: 22px;
                height: 22px;
            }
        }
    }

    .item-limit {
        padding: 16px 0 16px 0;
        display: flex;
        gap: 8px;
        color: $color-black;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;

        &-hide {
            display: none;
            padding: 0;
            gap: 8px;
        }
    }

    .limit {
        font-weight: 600;
    }

    .limit-title {
        color: $color-black;
        font-weight: 500;
    }

    @media (max-width: 600px) {
      margin-left: auto;
      margin-right: auto;
    }

}
