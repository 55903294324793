@import '~texkit/dist/styles/variables';

.feesContainer {
  padding: 0 24px;
  list-style: none;

  .feeItem {
    margin: 0 -24px;

    .key {
      width: 70%;

      &.tpwd {
        border-top: 1px solid #D5DBFF;
        border-bottom: 1px solid #D5DBFF;
        background: var(--Greyscale-White, #FFF);
        padding: 16px;
      }

      &.tpwdItem {
        padding: 16px;
      }
    }

    .value {
      width: 25%;
      text-align: right;
      font-weight: 600;
      padding: 16px 36px 16px 16px;

      .input {
        width: 120px;
        height: 48px;
        float: right;
        text-align: right;
        margin: -12px 0;

        @media screen and (max-width: 650px) {
          width: 100px;
        }
      }

      &.editable {
        padding-right: 16px;

        .input {
          padding: 0 16px 0 20px;
        }
      }

      &.tpwd {
        border-top: 1px solid #D5DBFF;
        border-bottom: 1px solid #D5DBFF;
        background: var(--Greyscale-White, #FFF);
        padding: 16px;
      }

      &.tpwdItem {
        padding: 16px;
      }
    }

    .input-wrapper.hasError {
      margin-bottom: 100px;
      height: 36px;

      .input-wrapper-field {
        position: absolute;
        right: 0;

        .input-message-error {
          top: 39px;
          position: absolute;
          text-transform: lowercase;
        }

        .input-message-error::first-letter {
          text-transform: capitalize;
        }
      }
    }

    &:nth-child(odd) {
      background-color: #F6F9FD;
    }

    &.subtotals {

      .key,
      .value {
        padding-bottom: 0;
        font-weight: 600;
      }
    }

    &.discount {

      .key,
      .value {
        font-weight: 400;
      }
    }

    &.discount {

      .key,
      .value {
        font-weight: 400;
      }
    }

    &.total {

      .key,
      .value {
        color: #304ffe;
        font-weight: 800;
        font-size: 22px;
      }
    }
  }
}