@import '~texkit/dist/styles/variables';
@import '~texkit/dist/styles/placeholders';

.tpwd-faq-modal {
  height: 100dvh;
  padding-bottom: 25px;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #F8F8F7; 
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: #C2C6CC; 
  }
  
  @media #{$mq-min-sm} {

    ::-webkit-scrollbar {
      width: 8px;
    }

    height: auto;
    padding: 0;
  }

}

.tpwd-faq-modal .modal-body {
  padding: 64px 30px 64px;
  @media #{$mq-min-sm} {
    padding: 40px 50px;
  }
}

.tpwd-faq-modal .modal-content-wrapper {
  height: calc(100dvh - 200px);
  overflow-y: auto;

  @media #{$mq-min-sm} {
    height: auto;
  }
}

.tpwd-faq-modal .modal-title-general {
  color: #304FFE;
}

.tpwd-faq-modal .modal-title {
  padding: 0px 32px;
  margin-bottom: 10px;
  text-align: left;
  font-size: 28px;

  @media #{$mq-min-sm} {
    font-size: 40px;
  }
}

.tpwd-faq-modal .modal-description {
  display: block;
  text-align: left;
  padding: 0px 10px 0 24px;
  margin: 0 0 16px 0 !important;

  @media #{$mq-min-sm} {
    padding: 0px 50px;
  }
}

.tpwd-faq-modal .modal-inner-body {
  width: 98%;
  padding: 0 ($padding * 2);
  text-align: left;

  @media #{$mq-min-sm} {
    height: 470px;
    overflow-y: auto;
    padding: 0 64px 14px 32px;
    margin: ($margin * 1) 0;
  }

  .accordion_section .accordion .accordion_title {
    font-size: 16px;
  }

  .accordion_section .accordion .accordion_title:after {
    font-size: 32px;
  }

  .accordion_section .accordion_content .accordion_text svg {
    display:inline-flex; 
    transform: scale(.7);
    overflow: visible;
    width: 16px;
    height: 16px; 
  }

}

.tpwd-faq-modal .modal-content p {
  color: #47525d;
  font-weight: bold;
}

.tpwd-faq-modal .modal-button {
  display: none;

  @media #{$mq-min-sm} {
    display: inline-block;
    margin-top: ($margin * 1);
  }
}

.tpwd-faq-modal .btn-wrapper {
  padding-top: 20px;
  @media #{$mq-min-sm} {
    padding-top: 0;
  }
}

.tpwd-faq-modal .modal-button-mobile {
  width: 100%;

  @media #{$mq-min-sm} {
    display: none;
  }
}
