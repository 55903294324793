@import '~texkit/dist/styles/variables';

.tx-options-modal {
    .modal-paragraph {
        color: $color-black;
        text-align: left;
        font-size: 16px;
    }
    .modal-list-section {
        color: $color-black;
        text-align: left;
        font-size: 16px;
        margin-bottom: 24px;
        ul {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    .modal-body {
        padding: 62px;
    }
    .modal-content {
        max-width: 630px;
    }

    .modal-title {
        @media only screen and (max-width: 850px) {
            font-size: 30px;
        }
        @media only screen and (max-width: 600px) {
            font-size: 24px;
        }
    }
}


.external-link-long svg {
    display:inline-flex; 
    transform: scale(.7);
    overflow: visible;
    width: 16px;
    height: 16px; 
}

.external-link {

    display:inline-flex; 

    .service-icon {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
      margin-left: 4px;
      margin-top: 2px;
  
      svg,
      path {
        fill: #304ffe;
        display: inline-block;
      }
    }

    a {
        text-wrap: wrap;
    }

  }