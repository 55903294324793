@import '~texkit/dist/styles/variables';

@font-face {
  font-family: 'Saira Extra Condensed';
  src: url('../../../assets/fonts/static/SairaExtraCondensed-SemiBold.ttf');
}


.service-icon {
  display: block;
  width: 100%;
  height: auto;

  &-tpwd {
    .text-container {
      max-width: 100% !important;
      // min-width: 
      text-align: left;
      position: inherit;
    }

    .text {
      font-family: 'Saira Extra Condensed';
      letter-spacing: 1px;
      font-size: 64px;
      font-weight: 800;
      width: 100%;
      background: linear-gradient(180deg, #003F30 0%, #008062 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

  }

  &-boat-plate {

    .text-container {
      max-width: 270px;
      text-align: left;
      position: inherit;
      margin: -15px;
    }

    .text {
      font-family: 'Saira Extra Condensed';
      letter-spacing: 1px;
      font-size: 57px;
      font-weight: 800;
      width: 100%;
      background: linear-gradient(180deg, #003F30 0%, #008062 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    @media (max-width: 420px) {
      .text {
        font-size: 48px;
      }
    }

    &-ios {

      .text {
        font-family: 'Saira Extra Condensed';
        letter-spacing: 1px;
        font-size: 64px;
        font-weight: 500;
        width: 100%;
        background: linear-gradient(180deg, #003F30 0%, #008062 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      @media (max-width: 420px) {
        .text {
          font-size: 46px;
          font-weight: 500;
          background: linear-gradient(180deg, #003F30 0%, #008062 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }


  &-license-plate {

    .text-container {
      background-image: url('../../../assets/service-icons/license-plate.png');
      background-repeat: no-repeat;
      background-size: contain;
      height: 158px;
      max-width: 250px;
      text-align: center;
      position: inherit;
    }

    .text {
      font-family: 'Saira Extra Condensed';
      letter-spacing: 1.5px;
      font-size: 50px;
      color: #21294E;
      padding-top: 33px;
      width: 100%;
      height: 100%;
      text-align: center;
      -webkit-transform: scaleY(1.2);
      transform: scaleY(1.2);
    }

    .modalText {
      font-family: 'Saira Extra Condensed';
      letter-spacing: 1.5px;
      font-size: 50px;
      color: #21294E;
      padding-top: 33px;
      width: 100%;
      height: 100%;
      text-align: center;
      -webkit-transform: scaleY(1.2);
      transform: scaleY(1.2);
      transition: .3s ease-in-out;

      @media only screen and (max-width: 800px) {
        font-size: 30px;
      }

      @media only screen and (max-width: $breakpoint-sm) {
        font-size: 50px;
      }

      @media only screen and (max-width: 551px) {
        font-size: 36px;
      }
    }
  }
}